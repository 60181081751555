import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import './App.css';
import Header from './Components/Header';
import Footer from './Components/Footer';
import About from './Components/About';
import Resume from './Components/Resume';
import Contact from './Components/Contact';
import Portfolio from './Components/Portfolio';
import ResumeContext from './contexts/resumeContext';

const App = (props) => {
  const [resumeData, setResumeData] = useState({});
  
  useEffect(() => {
    // ReactGA.initialize('UA-110570651-1');
    // ReactGA.pageview(window.location.pathname);
    getResumeData()
  }, [])

  function getResumeData(){
    console.log("getting data")
    $.ajax({
      url:'/resumeData.json',
      dataType:'json',
      cache: true,
      success: function(data){
        console.log("success", data)
        setResumeData(data)
      },
      error: function(xhr, status, err){
        console.log("error", err)
        console.log(err);
        alert(err);
      }
    });
  };

  return (
    <div className="App">
      <ResumeContext.Provider value={resumeData} >
        <Header data={resumeData.main}/>
        <About data={resumeData.main}/>
        <Resume data={resumeData.resume}/>
        <Portfolio data={resumeData.portfolio}/>
        {/* <Testimonials data={resumeData.testimonials}/> */}
        <Contact data={resumeData.main}/>
        <Footer data={resumeData.main}/>
      </ResumeContext.Provider>
    </div>
  );
};

export default App;
