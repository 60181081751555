import React from 'react';

const About = (props) => {
  if(!props.data){
    return null;
  }
  const {name, bio, address, email, resumeDownload } = props.data

  return (
    <section id="about">
      <div className="row">
        <div className="three columns">
          <img className="profile-pic"  src={'./images/profile.jpg'} alt="Tanya Trinkle Profile Pic" />
        </div>
        <div className="nine columns main-col">
          <h2>About Me</h2>

          <p>{bio}</p>
          <div className="row">
            <div className="columns contact-details">
              <h2>Contact Details</h2>
              <p className="address">
                <span>{name}</span><br />
                <span>
                  {address.city} {address.state}, {address.zip}
                </span>
                <br />
                <span>{email}</span>
              </p>
            </div>
            <div className="columns download">
              <p>
                <a href={resumeDownload} className="button"><i className="fa fa-download"></i>Download Resume</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
   );
};

export default About;
